/*
=====================================================================
*   Ceevee v1.0 Layout Stylesheet
*   url: styleshout.com
*   03-18-2014
=====================================================================

   TOC:
   a. General Styles
   b. Header Styles
   c. About Section
   d. Resume Section
   e. Portfolio Section
   f. Call To Action Section
   h. Contact Section
   i. Footer

===================================================================== */

/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */

body {
  background: #0f0f0f;
}

/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

header {
  position: relative;
  height: 800px;
  min-height: 500px;
  width: 100%;
  background: #161415 url(images/header-background.jpg) no-repeat top center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}

/* vertically center banner section */
header:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
header .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

header .banner-text {
  width: 100%;
}
header .banner-text h1 {
  font: 90px/1.1em 'opensans-bold', sans-serif;
  color: #fff;
  letter-spacing: -2px;
  margin: 0 auto 18px auto;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}
header .banner-text h3 {
  font: 18px/1.9em 'librebaskerville-regular', serif;
  color: #a8a8a8;
  margin: 0 auto;
  width: 70%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}
header .banner-text h3 span,
header .banner-text h3 a {
  color: #fff;
}
header .banner-text hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #2f2d2e;
  border-color: rgba(150, 150, 150, 0.1);
}

/* header social links */
header .social {
  margin: 24px 0;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}
header .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}
header .social li a {
  color: #fff;
}
header .social li a:hover {
  color: #11abb0;
}

/* scrolldown link */
header .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .scrolldown a:hover {
  color: #11abb0;
}

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap ul,
#nav-wrap li,
#nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

/* nav-wrap */
#nav-wrap {
  font: 12px 'opensans-bold', sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #2b2b2b;
}
.opaque {
  background-color: #333;
}

/* hide toggle button */
#nav-wrap > a.mobile-btn {
  display: none;
}

ul#nav {
  min-height: 48px;
  width: auto;

  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}

/* Links */
ul#nav li a {
  /* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
  text-decoration: none;
  text-align: left;
  color: #fff;

  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

ul#nav li a:active {
  background-color: transparent !important;
}

ul#nav li a:hover {
  text-decoration: underline;
}

/* ------------------------------------------------------------------ */
/* c. About Section
/* ------------------------------------------------------------------ */

#about {
  background: #2b2b2b;
  padding-top: 96px;
  padding-bottom: 66px;
  overflow: hidden;
}

#about a,
#about a:visited {
  color: #fff;
}
#about a:hover,
#about a:focus {
  color: #11abb0;
}

#about h2 {
  font: 22px/30px 'opensans-bold', sans-serif;
  color: #fff;
  margin-bottom: 12px;
}
#about p {
  line-height: 30px;
  color: #fff;
}
#about .profile-pic {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100%;
}
#about .contact-details {
  width: 41.66667%;
}
#about .download {
  width: 58.33333%;
  padding-top: 6px;
}
#about .main-col {
  padding-right: 5%;
}
#about .download .button {
  margin-top: 6px;
  background: #444;
}
#about .download .button:hover {
  background: #fff;
  color: #2b2b2b;
}
#about .download .button i {
  margin-right: 15px;
  font-size: 20px;
}

/* ------------------------------------------------------------------ */
/* d. Resume Section
/* ------------------------------------------------------------------ */

.resume-container {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 72px;
  overflow: hidden;
}

.resume-container a,
.resume-container a:visited {
  color: #11abb0;
}
.resume-container a:hover,
.resume-container a:focus {
  color: #313131;
}

.resume-container h1 {
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.resume-container h1 span {
  border-bottom: 3px solid #11abb0;
  padding-bottom: 6px;
}
.resume-container h3 {
  font: 25px/30px 'opensans-bold', sans-serif;
}

.resume-container .header-col {
  padding-top: 9px;
}
.resume-container .main-col {
  padding-right: 10%;
}

.work {
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
}

.resume-container .info {
  font: 16px/24px 'librebaskerville-italic', serif;
  color: #444;
  margin-bottom: 18px;
  margin-top: 9px;
}
.resume-container .info span {
  margin-right: 5px;
  margin-left: 5px;
}
.resume-container .date {
  font: 15px/24px 'opensans-regular', sans-serif;
  margin-top: 6px;
}

/* Add padding to each section so that the anchor links */
.resume-container section {
  padding-top: 60px;
}

/*----------------------------------------------*/
/*	Skill Bars
/*----------------------------------------------*/

.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left;
}
.bars .skills {
  margin-top: 36px;
  list-style: none;
}
.bar-expand {
  position: absolute;
  left: 0;
  top: 0;

  margin: 0;
  padding-right: 24px;
  background: #313131;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 3px 0 0 3px;
}

/* ------------------------------------------------------------------ */
/* h. Contact Section
/* ------------------------------------------------------------------ */

#contact {
  background: #191919;
  padding-top: 96px;
  padding-bottom: 102px;
  color: #636363;
}
#contact .section-head {
  margin-bottom: 42px;
}

#contact a,
#contact a:visited {
  color: #11abb0;
}
#contact a:hover,
#contact a:focus {
  color: #fff;
}

#contact h1 {
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #ebeeee;
  margin-bottom: 6px;
}
#contact h1 span {
  border-bottom: 3px solid #ebeeee;
  padding-bottom: 6px;
}

#contact h4 {
  font: 16px/24px 'opensans-bold', sans-serif;
  color: #ebeeee;
  margin-bottom: 6px;
}
#contact p.lead {
  font: 18px/36px 'opensans-light', sans-serif;
  padding-right: 3%;
}
#contact .header-col {
  padding-top: 6px;
}

/* contact section */
#contact label {
  font: 15px/24px 'opensans-bold', sans-serif;
  margin: 12px 0;
  color: #ebeeee;
  display: inline-block;
  width: 26%;
}
#contact input,
#contact textarea,
#contact select {
  padding: 18px 20px;
  color: #eee;
  background: #373233;
  margin-bottom: 42px;
  border: 0;
  outline: none;
  font-size: 15px;
  line-height: 24px;
  width: 65%;
}
#contact input:focus,
#contact textarea:focus,
#contact select:focus {
  color: #fff;
  background-color: #11abb0;
}
#contact button.submit {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  background: #0d0d0d;
  border: none;
  cursor: pointer;
  height: auto;
  display: inline-block;
  border-radius: 3px;
  margin-left: 26%;
}
#contact button.submit:hover {
  color: #0d0d0d;
  background: #fff;
}
#contact span.required {
  color: #11abb0;
  font-size: 13px;
}
#message-warning,
#message-success {
  display: none;
  background: #0f0f0f;
  padding: 24px 24px;
  margin-bottom: 36px;
  width: 65%;
  margin-left: 26%;
}
#message-warning {
  color: #d72828;
}
#message-success {
  color: #11abb0;
}

#message-warning i,
#message-success i {
  margin-right: 10px;
}
#image-loader {
  display: none;
  position: relative;
  left: 18px;
  top: 17px;
}

/* Twitter Feed */
#twitter {
  margin-top: 12px;
  padding: 0;
}
#twitter li {
  margin: 6px 0px 12px 0;
  line-height: 30px;
}
#twitter li span {
  display: block;
}
#twitter li b a {
  font: 13px/36px 'opensans-regular', Sans-serif;
  color: #474747 !important;
  border: none;
}

/* ------------------------------------------------------------------ */
/* i. Footer
/* ------------------------------------------------------------------ */

footer {
  padding-top: 48px;
  margin-bottom: 48px;
  color: #888;
  font-size: 14px;
  text-align: center;
  position: relative;
}

footer a,
footer a:visited {
  color: #aaa;
}
footer a:hover,
footer a:focus {
  color: #fff;
}

/* copyright */
footer .copyright {
  margin: 0;
  padding: 0;
}
footer .copyright li {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 24px;
}
.ie footer .copyright li {
  display: inline;
}
footer .copyright li:before {
  content: '\2022';
  padding-left: 10px;
  padding-right: 10px;
  color: #095153;
}
footer .copyright li:first-child:before {
  display: none;
}

/* Go To Top Button */
#go-top {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -30px;
}
#go-top a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #525252;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  color: #fff;
  font-size: 21px;
  line-height: 60px;
  border-radius: 100%;
}
#go-top a:hover {
  background-color: #0f9095;
}
